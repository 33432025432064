<template>
  <section class="mx-auto max-w-[75rem]">
    <section class="module-skewed-background-small my-24 px-9 md:px-12">
      <div class="hero">
        <div class="hero-big">
          <h1 class="title fadeInText">{{ page.headline }}</h1>
          <p
            v-if="page.lead"
            class="caption fadeInText whitespace-pre-line pt-4"
            v-html="page.lead"
          />

          <!-- LINK -->
          <template
            v-if="
              page.hero_link && page.slug !== 'jobs' && page.slug !== 'news'
            "
          >
            <NuxtLink
              class="link button-primary mt-24 inline-block !font-medium !text-white"
              :to="page.hero_link"
            >
              {{ page.hero_link_text }}
            </NuxtLink>
          </template>

          <!-- BUTTON SCROLL TO -->
          <template
            v-if="
              page.slug === 'jobs' ||
              page.slug === 'news' ||
              page.slug === 'agenda'
            "
          >
            <button
              class="link button-primary mt-24 !font-medium !text-white after:!pr-5"
              @click="scrollTo()"
            >
              {{ page.hero_link_text }}
            </button>
          </template>

          <!-- IMAGE -->
          <img
            v-if="page.illustration"
            class="image"
            :src="page.illustration.permalink"
            :alt="page.illustration.alt"
          />
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  methods: {
    scrollTo() {
      let element =
        document.getElementById('job-setup') ||
        document.getElementById('newsletter') ||
        document.getElementById('event-setup')
      if (!element) {
        return
      }
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    },
  },
}
</script>

<style scoped>
@keyframes fadeInText {
  0% {
    opacity: 0;
    transform: scaleY(0);
    transform: translate3d(0, 0.5em, 0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform: translate3d(0);
  }
}
.fadeInText {
  animation-delay: 300ms;
  animation: 0.6s ease-out fadeInText;
}
</style>
