<template>
  <div class="w-full">
    <button
      v-if="withHeader"
      class="flex w-full items-center justify-between"
      @click="toggle()"
    >
      <slot name="header" />
      <ArrowSimpleIcon
        class="w-5 transition-transform"
        :class="{ 'rotate-180': isOpen }"
        stroke-width="1.5"
      />
    </button>

    <div
      ref="accordion"
      class="overflow-hidden transition-all"
      style="max-height: 0"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },

    withHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  watch: {
    open() {
      this.toggle()
    },
  },

  mounted() {
    this.isOpen = this.open
    if (this.isOpen) {
      this.setOpen()
    } else {
      this.setClose()
    }
  },

  methods: {
    toggle() {
      if (this.isOpen) {
        this.setClose()
      } else {
        this.setOpen()
      }

      this.$emit('toggle', this.$refs.accordion.scrollHeight)
    },

    async setClose() {
      this.$refs.accordion.style.maxHeight = 0
      this.isOpen = false
    },

    maxHeight() {
      return this.$el.clientHeight
    },

    async setOpen(additionalHeight = 0) {
      await this.$nextTick()
      this.$refs.accordion.style.maxHeight =
        this.$refs.accordion.scrollHeight + additionalHeight + 'px'
      this.isOpen = true
    },
  },
}
</script>
