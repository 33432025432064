import { defineRule, configure } from 'vee-validate'

import { required, email, numeric, max, image } from '@vee-validate/rules'

export default defineNuxtPlugin(() => {
  defineRule('required', required)
  defineRule('email', email)
  defineRule('numeric', numeric)
  defineRule('max', max)
  defineRule('image', image)

  defineRule('phone', (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }
    // Check if phone
    if (/^(\+|0|00)([0-9]){9,}$/.test(value.replace(/\s/g, ''))) {
      return true
    }
    return true
  })
  // after:today
  defineRule('after_today', (value) => {
    if (!value) {
      return true
    }

    return new Date(value) > new Date()
  })

  defineRule('after_time', (to, from) => {
    if (!from[0] || !to) {
      return true
    }
    return from[0] < to
  })

  defineRule('url', (value) => {
    const urlRegex =
      /^(http|https):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i

    return urlRegex.test(value)
  })

  // register locales
  configure({
    // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnBlur: true,
    // controls if `change` events should trigger validation with `handleChange` handler
    validateOnChange: true,
    // controls if `input` events should trigger validation with `handleChange` handler
    validateOnInput: true,
    // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true,
  })
})
