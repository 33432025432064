<template>
  <div class="px-6" />
  <HeroDefault :page="jobsPage" />

  <!-- JOB DETAIL -->
  <div
    class="mb-36 w-full skew-y-[-7.5deg] bg-primary-blue-gray-light py-16 px-6 text-primary-blue sm:mb-52 sm:skew-y-[-4deg] md:skew-y-[-2.5deg]"
  >
    <div
      class="mx-auto max-w-75rem skew-y-[7.5deg] sm:skew-y-[4deg] md:skew-y-[2.5deg]"
    >
      <h2 class="typo-heading4 mb-4 font-medium">
        {{ translations.shared_content }}
      </h2>
      <button class="relative mb-14 text-left" @click="copyToClipboard()">
        <span class="mr-3">{{ pageLink }}</span>
        <CopyIcon class="h-7 w-7" />
        <transition name="fade">
          <CopyToClipboardPopup v-show="showPopup">
            {{ translations.link_is_copied }}</CopyToClipboardPopup
          >
        </transition>
      </button>

      <!-- JOB DETAIL -->
      <JobCard :data="page" />
    </div>
  </div>

  <JobsOverview />
  <JobSetup />
</template>

<script>
import { ref } from 'vue'
import { store } from '/store.js'

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  async setup() {
    const { data } = await useAsyncGql({
      operation: 'EntryHero',
      variables: {
        uri: '/jobs',
        site: useI18n().locale,
      },
    })

    const jobsPage = ref(data?.value?.entry || {})

    return {
      jobsPage,
    }
  },

  data() {
    return {
      showPopup: false,
    }
  },

  computed: {
    translations() {
      return store.translations
    },

    pageLink() {
      return this.$config.public.APP_URL + this.page.url
    },
  },

  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.pageLink)
        this.showPopup = true

        setTimeout(() => {
          this.showPopup = false
        }, 1000)
      } catch (err) {
        console.error('Failed to copy to clipboard: ', err)
      }
    },
  },
}
</script>
