<template>
  <ul class="flex flex-wrap">
    <li>
      <button
        class="mr-3 mb-3 flex h-fit min-h-[2rem] items-center rounded-[1.5em] border-2 border-primary-blue px-3 py-2 text-left text-base font-medium transition-colors hover:bg-primary-blue hover:text-white sm:min-h-[3rem] sm:px-4"
        :class="filtered ? 'text-primary-blue' : 'bg-primary-blue text-white'"
        @click="$emit('filter', null)"
      >
        {{ buttonAllText }}
      </button>
    </li>

    <li v-for="term in terms" :key="term.id">
      <button
        class="mr-3 mb-3 flex h-fit min-h-[2rem] items-center rounded-[1.5em] border-2 border-primary-blue px-3 py-2 text-left text-base font-medium transition-colors hover:bg-primary-blue hover:text-white sm:min-h-[3rem] sm:px-4"
        :class="
          filtered === term.slug
            ? 'bg-primary-blue text-white'
            : 'text-primary-blue'
        "
        @click="$emit('filter', term.slug)"
      >
        {{ term.title }}
      </button>
    </li>
  </ul>
</template>

<script>
import { store } from '/store.js'
export default {
  props: {
    terms: {
      type: Array,
      default: () => [],
    },

    filtered: {
      type: String,
      default: null,
    },

    buttonAllText: {
      type: String,
      default: 'Alle',
    },
  },

  computed: {
    translations() {
      return store.translations
    },
  },
}
</script>
