<template>
  <div
    class="job-card relative grid w-full grid-cols-1 gap-12 md:grid-cols-3 md:gap-0"
  >
    <!-- DATE -->
    <div
      class="absolute -top-4 -left-3 z-10 skew-x-[-15deg] -skew-y-3 bg-primary-blue px-3 py-2 text-white sm:py-3 sm:px-4"
    >
      <p
        class="skew-x-[15deg] skew-y-3 text-xl font-medium text-white md:text-2xl xl:text-[1.75rem]"
      >
        {{ formatDate }}
      </p>
    </div>

    <!-- IMAGE -->
    <div class="aspect-container relative col-span-1 w-full after:!hidden">
      <NuxtLink
        class="h-full w-full"
        target="_blank"
        :to="data.link"
        :aria-label="`Open event page ${data.title}`"
      >
        <img
          v-if="data.image"
          class="image h-full w-full object-cover"
          :src="data.image.permalink"
          :alt="data.image.alt"
        />

        <!-- PLACEHOLDER -->
        <div v-else class="placeholder absolute inset-0 flex items-center">
          <div class="title-white px-14">
            <div class="pt-16">{{ data.title }}</div>
          </div>
        </div>
      </NuxtLink>
    </div>
    <!-- TEXT -->
    <div class="md:col-span-2 md:pl-6 lg:pl-20">
      <h3 class="mb-3">
        <NuxtLink target="_blank" :to="data.event_link" class="title">
          {{ data.title }}
        </NuxtLink>
      </h3>

      <!-- ORGANIZER -->
      <p
        v-if="data.event_organizer?.title"
        target="_blank"
        class="subtitle mb-4 font-medium"
      >
        <!-- {{ `${translations.event_organizer}:` }} -->
        {{ data.event_organizer?.title }}
      </p>

      <p
        v-if="data.description"
        class="my-5 text-black md:my-6"
        v-html="data.description"
      />

      <!-- META -->
      <div class="flex flex-wrap gap-4">
        <!-- TYPE -->
        <div
          v-if="data.event_type"
          class="rounded border-2 border-primary-red bg-primary-red py-2 px-3 font-semibold uppercase text-white sm:py-3 sm:px-4"
        >
          {{ data.event_type?.title }}
        </div>
        <!-- LOCATION & ONLINE -->
        <div
          v-if="data.location || data.online"
          class="rounded border-2 border-primary-blue py-2 px-3 font-semibold uppercase text-primary-blue sm:py-3 sm:px-4"
        >
          {{ location }}
        </div>

        <!-- TIME -->
        <div
          v-if="data.time"
          class="rounded border-2 border-primary-blue py-2 px-3 font-semibold uppercase text-primary-blue sm:py-3 sm:px-4"
        >
          {{ data.time }} - {{ data.time_until }}
        </div>
        <!-- TO CALENDAR -->
        <button
          ref="calendarButton"
          class="relative flex w-[3.25rem] items-center justify-center rounded border-2 border-primary-blue-gray-light bg-primary-blue-gray-light p-2 transition-colors hover:border-primary-blue-gray hover:bg-primary-blue-gray"
          aria-label="Add event to calendar"
          @click="addToCalendar()"
        >
          <ToCalendarIcon class="w-5 text-black sm:w-6" />
        </button>
      </div>

      <!-- LINKS -->
      <div
        class="mt-7 flex w-full flex-col flex-wrap justify-between gap-y-3 sm:flex-row md:mt-12"
      >
        <div class="link link-button text-base">
          <ButtonDefault :to="data.event_link">
            {{ translations.event_info_and_registration }}
          </ButtonDefault>
        </div>

        <button
          v-if="isDetail"
          class="button-primary after:content-none"
          @click="copyToClipboard()"
        >
          <span
            class="copy-button mr-3 text-base font-medium tracking-widest"
            >{{ translations.link_to_share }}</span
          >
          <CopyIcon class="h-7 w-7" />
          <transition name="fade">
            <CopyToClipboardPopup v-show="showPopup">
              {{ translations.link_is_copied }}</CopyToClipboardPopup
            >
          </transition>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '/store.js'
import { atcb_action } from 'add-to-calendar-button'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    isDetail: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPopup: false,
    }
  },

  computed: {
    translations() {
      return store.translations
    },

    location() {
      let location = this.data.location
      if (this.data.location && this.data.online) {
        location = `${this.data.location} & ${this.translations.event_online}`
      }
      if (!this.data.location && this.data.online) {
        location = this.translations.event_online
      }
      return location
    },

    formatDate() {
      if (!this.data.date) return ''
      const locale = useI18n().locale.value
      return new Date(this.data.date).toLocaleDateString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    },
  },

  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(
          this.$config.public.APP_URL + this.data.url
        )
        this.showPopup = true

        setTimeout(() => {
          this.showPopup = false
        }, 1000)
      } catch (err) {
        console.error('Failed to copy to clipboard: ', err)
      }
    },

    addToCalendar() {
      const event = {
        name: this.data.title,
        description: this.data.description,
        startDate: this.data.date,
        location: this.location,
        startTime: this.data.time,
        endTime: this.data.time_until,
        options: [
          'Apple',
          'Google',
          'iCal',
          'Microsoft365',
          'MicrosoftTeams',
          'Outlook.com',
          'Yahoo',
        ],

        timeZone: 'Europe/Zurich',
      }

      atcb_action(event, this.$refs.calendarButton)
    },
  },
}
</script>
