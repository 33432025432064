<template>
  <div
    class="fixed inset-x-0 top-0 z-20 bg-white text-primary-blue transition-all"
    :class="[
      { 'nav-shadow': scrollOffset > 0 },
      { '-translate-y-24': hideNavigation },
      { '!nav-shadow': open },
    ]"
  >
    <div class="h-20 px-6">
      <div
        class="flex h-full w-full items-center justify-between"
        :class="{ 'border-primary-light border-b': open }"
      >
        <!-- LOGO -->
        <NuxtLink :to="localePath('/')" aria-label="Link to Homepage">
          <img
            v-if="header.logo"
            class="h-full w-full max-w-[16rem]"
            :src="header.logo.permalink"
            :alt="header.logo.alt"
          />
        </NuxtLink>

        <!-- DESKTOP NAVIGATION -->
        <nav class="nav-links hidden h-full items-center xl:flex">
          <ul class="flex h-full items-center">
            <li
              v-for="entry in navigation"
              :key="entry.page.id"
              class="relative mr-10 flex h-full items-center"
              :class="{ group: entry.children }"
            >
              <!-- PARENT -->
              <NuxtLink
                :to="entry.page.url"
                class="link inline-block cursor-pointer font-medium"
              >
                {{ entry.page.title }}
              </NuxtLink>
              <!-- CHILDREN DROPDOWN -->
              <ul
                v-if="entry.children"
                class="dropdown-shadow pointer-events-none absolute -left-6 top-[4.5rem] w-fit min-w-[10rem] bg-white opacity-0 transition-opacity duration-300 group-hover:pointer-events-auto group-hover:opacity-100"
              >
                <li
                  v-for="child in entry.children"
                  :key="child.page.id"
                  class="w-full"
                >
                  <NuxtLink
                    :to="child.page.url"
                    class="link child inline-block w-full whitespace-nowrap px-6 py-3 font-medium hover:bg-primary-blue-gray-light"
                    :class="{ 'is-active': child.page.url === $route.path }"
                  >
                    {{ child.page.title }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li>
              <NuxtLink
                class="item searchlink button-secondary !no-underline"
                :to="header.offersearch.url"
                >{{ header.search }}</NuxtLink
              >
            </li>

            <li class="ml-4">
              <LangPicker />
            </li>
          </ul>
        </nav>

        <!-- MOBILE OPEN CLOSE -->
        <button
          :aria-label="open ? 'close navigation' : 'open navigation'"
          class="flex h-9 w-9 items-center justify-center xl:hidden"
          @click="open = !open"
        >
          <TogglerIcon v-show="!open" class="h-5 w-9 text-primary-red" />
          <CrossIcon v-show="open" class="h-5 w-5 text-primary-red" />
        </button>
      </div>
    </div>

    <!-- MOBILE NAVIGATION -->
    <Accordion ref="wrapper-accordion" :open="open" class="shadow">
      <template #content>
        <ul class="nav-links space-y-4 bg-white p-6 text-[1.1875rem] xl:hidden">
          <li v-for="entry in navigation" :key="entry.page.id">
            <!-- CHILDREN -->
            <Accordion
              v-if="entry.children.length"
              with-header
              @toggle="reCalc($event)"
            >
              <template #header>
                <div class="link font-medium">
                  {{ entry.page.title }}
                </div>
              </template>
              <template #content>
                <ul class="space-y-4 px-4 pb-4 first:pt-4">
                  <li v-for="child in entry.children" :key="child.page.id">
                    <NuxtLink
                      :to="child.page.url"
                      class="link child font-normal"
                    >
                      {{ child.page.title }}
                    </NuxtLink>
                  </li>
                </ul>
              </template>
            </Accordion>

            <!-- NORMAL LINK -->
            <NuxtLink v-else :to="entry.page.url" class="link font-medium">
              {{ entry.page.title }}
            </NuxtLink>
          </li>

          <li class="flex items-center justify-between pt-5">
            <NuxtLink
              class="item searchlink button-primary !font-medium"
              :to="header.offersearch.url"
              >{{ header.search }}</NuxtLink
            >
            <LangPicker />
          </li>
        </ul>
      </template>
    </Accordion>
  </div>
</template>

<script>
import { store } from '~/store'
import debounce from 'lodash/debounce'
export default {
  data() {
    return {
      open: false,
      scrollOffset: 0,
      hideNavigation: false,
    }
  },

  computed: {
    navigation() {
      return store.navigation?.main?.tree || {}
    },

    header() {
      return store.header || {}
    },

    pageSlug() {
      return store.pageSlug || ''
    },
  },

  watch: {
    $route() {
      this.open = false
    },

    hideNavigation() {
      this.open = false
    },
  },

  mounted() {
    this.handleScroll = debounce(this.handleScroll, 100)
    // event listener scroll
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      const newValue = window.pageYOffset || document.documentElement.scrollTop

      // Do not hide navigation on home page
      if (this.pageSlug === 'home') return

      if (newValue > this.scrollOffset) {
        this.hideNavigation = true
      } else {
        this.hideNavigation = false
      }
      this.scrollOffset = newValue
    },

    reCalc(additionalHeight) {
      this.$refs['wrapper-accordion'].setOpen(additionalHeight)
    },
  },
}
</script>

<style lang="scss">
.dropdown-shadow {
  box-shadow: 0px 6px 12px 0px rgba(10, 45, 115, 0.1);
}

.nav-shadow {
  box-shadow: 0 5px 7px 3px rgba(0, 0, 0, 0.13);
}

.nav-links {
  a.router-link-active {
    text-decoration: underline;
    -webkit-text-decoration-color: #e51b0e;
    text-decoration-color: #e51b0e;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.2rem;
    text-underline-position: under;
  }
  a.child.router-link-active {
    text-decoration: none;
    background-color: #f2f4f8;
  }
}
</style>
