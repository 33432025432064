<template>
  <Container>
    <section class="module-default mx-auto max-w-[75rem]">
      <!-- IMAGE AND VIDEO -->
      <div
        v-if="
          data.paragraph_type?.value === 'image' ||
          data.paragraph_type?.value === 'video'
        "
        class="content-section -has-image"
        :class="[
          { '-image-right': data.layout?.value === 'image_right' },
          { '-image-left': data.layout?.value === 'image_left' },
        ]"
      >
        <div class="contentwrapper">
          <h2 class="title">{{ data.headline }}</h2>
          <div v-if="data.lead" class="lead" v-html="data.lead" />
          <div class="copy">
            <div v-if="data.paragraph" v-html="data.paragraph" />
            <NuxtLink
              v-if="data.link"
              :to="data.link"
              class="button button-primary"
              :class="{ '-is-external': $isExternalUrl(data.link) }"
              :target="$isExternalUrl(data.link) ? '_blank' : ''"
            >
              {{ data.link_text }}
            </NuxtLink>
          </div>
        </div>
        <div class="imagewrapper">
          <!-- IMAGE -->
          <img
            v-if="data.paragraph_type?.value === 'image' && data.image"
            class="image"
            :style="`aspect-ratio: ${data.image_handling?.value}`"
            :src="data.image.permalink"
            :alt="data.image.alt"
          />
          <!-- VIDEO -->

          <template
            v-if="data.paragraph_type?.value === 'video' && data.video_embedded"
          >
            <!-- Youtube and Vimeo -->
            <div
              v-if="isEmbeddable(data.video_embedded)"
              class="video-container"
              :style="`aspect-ratio: ${data.image_handling.value}`"
            >
              <iframe
                class="video"
                :src="embedUrl(data.video_embedded)"
                frameborder="0"
              />
            </div>
            <!-- Other HTML5 video types -->
            <video v-else class="image" :src="embedUrl(data.video_embedded)" />
          </template>
        </div>
      </div>

      <!-- TEXT -->
      <div v-else class="content-section">
        <div class="titlewrapper">
          <!-- only in variant without image -->
          <h2 v-if="data.subtopic" class="title" v-html="data.subtopic" />
        </div>
        <div class="contentwrapper">
          <div v-if="data.lead" class="lead" v-html="data.lead" />

          <div class="copy">
            <div v-if="data.paragraph" v-html="data.paragraph" />
            <NuxtLink
              v-if="data.link"
              :to="data.link"
              class="button button-primary"
              :class="{ '-is-external': $isExternalUrl(data.link) }"
              :target="$isExternalUrl(data.link) ? '_blank' : ''"
            >
              {{ data.link_text }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </section>
  </Container>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      youtubeIframe: null,
    }
  },
}
</script>
