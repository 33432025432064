<template>
  <div>
    <div class="relative">
      <Field
        v-model="innerValue"
        class="peer z-10"
        :class="{
          '!border-primary-red': errors[field.handle],
          ' !pl-12': hasIcon,
        }"
        :name="field.handle"
        :type="field.config.input_type"
        :placeholder="translations[field.handle]"
        style="color-scheme: dark"
        :disabled="disabled"
        :rules="validVeeRules"
      />
      <label
        class="peer-placeholder-shown:text-custom-light-blue-gray pointer-events-none absolute -top-3 mr-1 text-sm text-primary-blue-gray transition-all line-clamp-1 peer-placeholder-shown:top-[1.125rem] peer-placeholder-shown:!text-[1.1875rem] peer-focus:-top-3 peer-focus:!text-sm peer-focus:text-white sm:line-clamp-none"
        :for="field.handle"
        :class="[
          {
            '!text-primary-red peer-placeholder-shown:!text-custom-light-blue-gray':
              errors[field.handle],
          },
          {
            '!text-white':
              field.config.input_type === 'date' ||
              field.config.input_type === 'time',
          },
          hasIcon ? 'left-11' : 'left-3',
        ]"
      >
        {{ translations[field.handle] }}
      </label>
      <div
        v-if="hasIcon"
        class="pointer-events-none absolute inset-y-0 left-0 flex w-14 items-center justify-center"
      >
        <LocationIcon
          v-if="field.handle === 'location'"
          class="h-6 w-6 text-white"
        />
        <LinkIcon
          v-if="field.handle === 'link' || field.handle === 'company_link'"
          class="h-7 w-7 text-white"
        />
      </div>
    </div>

    <p v-if="errors[field.handle]" class="error !text-sm">
      {{ translations[field.handle] }}
      <span
        v-if="
          field.handle === 'link' ||
          field.handle === 'company_link' ||
          field.handle === 'event_link'
        "
        class="text-sm"
      >
        {{ translations.link_error }}
      </span>
      <span
        v-else-if="field.handle === 'start' || field.handle === 'event_date'"
        class="text-sm"
      >
        {{ translations.start_error }}
      </span>
      <span v-else-if="field.handle === 'event_time_until'" class="text-sm">
        {{ translations.time_start_error }}
      </span>

      <span v-else class="text-sm">
        {{ translations.is_required }}
      </span>
    </p>
  </div>
</template>

<script>
import { store } from '@/store'
import { Field } from 'vee-validate'

export default {
  components: {
    Field,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    value: {
      type: null,
      required: true,
    },

    rules: {
      type: [Array, String],
      default: '',
    },

    errors: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: this.value,
    }
  },

  computed: {
    translations() {
      return store.translations
    },

    validVeeRules() {
      let rules = this.rules
        .filter((r) => r !== 'nullable' && r !== 'required_without:from_now')
        .join('|')

      return rules.replace('after:today', 'after_today')
    },

    hasIcon() {
      return (
        this.field.handle === 'location' ||
        this.field.handle === 'link' ||
        this.field.handle === 'company_link'
      )
    },
  },

  watch: {
    innerValue(innerValue) {
      this.$emit('field-input', innerValue)
    },

    value(value) {
      this.innerValue = value
    },
  },
}
</script>
