<template>
  <footer
    id="site-footer"
    class="mt-12 w-full px-6 pt-9 pb-7 sm:mt-20 xl:mt-24"
  >
    <div class="site-footer">
      <NuxtLink
        class="branding"
        to="/"
        :title="translations.to_home"
        :aria-label="translations.to_home"
      >
        <img
          v-if="footer.logo"
          :src="footer.logo.permalink"
          class="logo"
          :alt="footer.logo.alt"
        />
      </NuxtLink>
      <div class="content">
        <div class="contact footer-address mr-8 whitespace-nowrap">
          <NuxtLink
            class="link !text-base after:!px-2"
            :to="`https://www.google.ch/maps?q=${footer.address}`"
            >{{ footer.address }}</NuxtLink
          >
          <NuxtLink
            class="link !text-base after:!px-2"
            target="_blank"
            :to="`tel:${footer.phone}`"
            >{{ footer.phone }}</NuxtLink
          >
          <NuxtLink
            class="link !text-base"
            target="_blank"
            :to="`mailto:${footer.email}`"
            >{{ footer.email }}</NuxtLink
          >
        </div>

        <div class="pages footer-pages space-x-8">
          <NuxtLink
            v-if="footer.privacy"
            class="button !text-base"
            :to="footer.privacy.url"
          >
            {{ footer.privacy.title }}
          </NuxtLink>
          <NuxtLink
            v-if="footer.imprint"
            class="button !text-base"
            :to="footer.imprint.url"
          >
            {{ footer.imprint.title }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { store } from '/store.js'
export default {
  computed: {
    translations() {
      return store.translations || {}
    },

    footer() {
      return store.footer || {}
    },
  },
}
</script>
