<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.5 12"
    stroke-width="currentColor"
  >
    <path
      d="m18.397 1.016-8.512 9.622C9.812 10.716.797 1.016.797 1.016"
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
    />
  </svg>
</template>
