<template>
  <div
    class="flex flex-col space-y-5 sm:flex-row sm:items-center sm:space-x-10 sm:space-y-0"
  >
    <p class="whitespace-nowrap text-base">
      {{ translations.social_media_links_title }}
    </p>
    <div class="flex space-x-7">
      <NuxtLink
        target="_blank"
        :to="`https://www.facebook.com/sharer/sharer.php?u=${permalink}`"
        aria-label="facebook"
        class="shrink-0"
      >
        <FacebookIcon class="h-11 w-11" />
      </NuxtLink>
      <NuxtLink
        target="_blank"
        :to="`https://www.linkedin.com/shareArticle?mini=true&url=${permalink}`"
        aria-label="linkedin"
        class="shrink-0"
      >
        <LinkedinIcon class="h-11 w-11" />
      </NuxtLink>
      <NuxtLink
        target="_blank"
        class="shrink-0"
        :to="`https://twitter.com/intent/tweet?url=${permalink}`"
      >
        <TwitterIcon class="h-11 w-11" />
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import { store } from '/store.js'
export default {
  props: {
    permalink: {
      type: String,
      required: true,
    },
  },

  computed: {
    translations() {
      return store.translations
    },
  },
}
</script>
