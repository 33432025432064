export default defineNuxtPlugin(() => {
  return {
    provide: {
      isExternalUrl: (url: string) => {
        if (!url) return null
        return (
          typeof url === 'string' &&
          url.match(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
          )
        )
      },
    },
  }
})
