<template>
  <section
    id="newsletter"
    class="newsletter background-aslant module-default relative flex w-full justify-center px-6 pb-32"
  >
    <div class="w-full max-w-[55rem]">
      <!-- TITLE -->

      <div class="mb-6 pt-24 sm:pt-40">
        <h2 class="title">
          {{
            data.newsletter_title
              ? data.newsletter_title
              : translations.newsletter_title
          }}
        </h2>
      </div>

      <div v-if="state !== 'submitted'" class="mb-12">
        <p class="text">
          {{
            data.newsletter_text
              ? data.newsletter_text
              : translations.newsletter_text
          }}
        </p>
      </div>

      <!-- SUCCESS -->
      <div
        v-show="state === 'submitted'"
        ref="success"
        class="my-10 flex items-start space-x-4 text-white"
      >
        <CheckmarkIcon class="mt-2 h-4 w-4 shrink-0" />
        <p class="success">
          {{ translations.newsletter_success_message }}
        </p>
      </div>

      <!-- ERROR -->
      <div
        v-show="state === 'error'"
        ref="error"
        class="my-10 flex items-start text-primary-red"
      >
        <p>
          {{ translations.submit_error }}
        </p>
      </div>

      <!-- FORM -->
      <Form
        ref="form"
        v-slot="{ errors }"
        novalidate
        class="form text-white"
        @submit="submit"
      >
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-6 sm:gap-8">
          <!-- NAME : currently no in use -->
          <!-- <div class="input relative sm:col-span-2">
            <Field
              v-model="name"
              rules="required"
              class="peer"
              name="name"
              type="text"
              :class="{ '!border-primary-red': errors['name'] }"
              :placeholder="translations.newsletter_name"
            />
            <label
              class="peer-placeholder-shown:text-custom-light-blue-gray pointer-events-none absolute -top-3 left-3 text-sm text-primary-blue-gray transition-all peer-placeholder-shown:top-[1.125rem] peer-placeholder-shown:!text-[1.1875rem] peer-focus:-top-3 peer-focus:!text-sm peer-focus:text-white"
              for="name"
              :class="{
                'peer-placeholder-shown:!text-custom-light-blue-gray !text-primary-red':
                  errors['name'],
              }"
            >
              {{ translations.newsletter_name }}
            </label>

            <p v-if="errors['name']" class="error text-sm">
              {{ translations.newsletter_name }}
              {{ translations.is_required }}
            </p>
          </div> -->

          <!-- EMAIL -->
          <div class="input relative col-span-6">
            <Field
              v-model="email"
              rules="required|email"
              name="email"
              class="peer"
              :class="{ '!border-primary-red': errors['email'] }"
              type="text"
              :placeholder="translations.newsletter_email"
            />
            <label
              class="peer-placeholder-shown:text-custom-light-blue-gray pointer-events-none absolute -top-3 left-3 text-sm text-primary-blue-gray transition-all peer-placeholder-shown:top-[1.125rem] peer-placeholder-shown:!text-[1.1875rem] peer-focus:-top-3 peer-focus:!text-sm peer-focus:text-white"
              for="email"
              :class="{
                'peer-placeholder-shown:!text-custom-light-blue-gray !text-primary-red':
                  errors['email'],
              }"
            >
              {{ translations.newsletter_email }}
            </label>
            <p v-if="errors['email']" class="error text-sm">
              {{ translations.newsletter_email }}
              {{ translations.is_required }}
            </p>
          </div>
        </div>

        <input
          v-model="honeypot"
          type="text"
          class="hidden"
          :name="data.honeypot ?? 'honeypot'"
        />

        <button
          class="button-secondary mt-10 !tracking-widest sm:mt-14"
          type="submit"
          :disabled="state === 'loading' || state === 'submitted'"
        >
          {{
            data.newsletter_button_text
              ? data.newsletter_button_text
              : translations.newsletter_button_text
          }}
        </button>
      </Form>
    </div>
  </section>
</template>

<script>
import { store } from '/store.js'
import { Form, Field } from 'vee-validate'

export default {
  components: {
    Form,
    Field,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      name: '',
      email: '',
      honeypot: '',
      state: 'default',
    }
  },

  computed: {
    translations() {
      return store.translations || {}
    },
  },

  methods: {
    scrollTo(element) {
      if (!element) return

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    },

    async submit() {
      this.state = 'loading'

      try {
        await $fetch(`${this.$config.public.API_URL}/!/forms/newsletter`, {
          method: 'POST',
          body: {
            // name: this.name,
            email: this.email,
            honeypot: this.honeypot,
          },
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        })

        this.state = 'submitted'

        this.scrollTo(this.$refs.success)
      } catch (e) {
        this.state = 'error'

        console.error(e)

        await this.$nextTick()
        this.scrollTo(this.$refs.error)
      }
    },
  },
}
</script>
