<template>
  <div :key="locale" class="relative h-[3.125rem] w-[3.125rem]">
    <ul
      class="absolute inset-x-0 top-0 flex min-h-[3.125rem] w-[3.125rem] cursor-pointer flex-col items-center overflow-hidden rounded-full border-2 border-primary-red p-2"
    >
      <li
        v-for="item in sortedLocales"
        :key="item.code"
        :class="open ? 'pointer-events-auto' : 'pointer-events-none'"
      >
        <NuxtLink
          v-show="open || item.code === locale"
          aria-label="switch-language"
          class="link my-1 text-base font-semibold uppercase text-primary-red"
          :class="item.code === locale ? '!no-underline' : 'hover:underline'"
          :to="switchLocalePath(item.code)"
          >{{ item.code }}</NuxtLink
        >
      </li>
    </ul>
    <button
      aria-label="open-language-picker"
      class="absolute h-[3.125rem] w-[3.125rem] rounded-full"
      @click="open = !open"
    />
  </div>
</template>

<script setup>
const switchLocalePath = useSwitchLocalePath()
const { locale, locales } = useI18n()
const open = ref(false)

const sortedLocales = computed(() => {
  const sorted = []
  sorted.push(locales.value.find((item) => item.code === locale.value))
  return sorted.concat(
    locales.value.filter((item) => item.code !== locale.value)
  )
})

watch(locale, () => {
  open.value = false
})
</script>
