<template>
  <div class="px-6">
    <NewsDetail :page="page" />

    <div
      class="mx-auto mb-32 flex max-w-[75rem] flex-col-reverse sm:mb-44 sm:flex-row sm:items-center sm:justify-between"
    >
      <!-- BACK LINK -->

      <NuxtLink
        class="button-primary -backwards mt-12 w-fit sm:mt-0"
        to="/news"
        >{{ translations.back_link_text }}</NuxtLink
      >

      <!-- SOCIAL MEDIA SHARES -->
      <SocialMediaShares :permalink="$config.public.APP_URL + page.url" />
    </div>
  </div>

  <NewsArticlesTeaser />

  <Newsletter />
</template>

<script>
import { store } from '/store.js'
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  computed: {
    translations() {
      return store.translations
    },
  },
}
</script>
