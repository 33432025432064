<template>
  <div
    class="hero-home z-10 w-full max-w-[100vw] bg-white pt-12 pr-6 pb-[30vh] pl-20 sm:max-w-[38rem] sm:pt-20 sm:pr-12 sm:pl-28 xl:max-w-2xl xl:pl-36 xl:pr-20 xl:pt-24 3xl:max-w-[57rem]"
  >
    <div class="revert-skew">
      <h1
        class="hyphens-auto xl:hyphens-none mb-5 text-2xl font-medium lg:text-3xl 3xl:text-5xl"
      >
        {{ page.headline }}
      </h1>
      <p
        class="mb-6 pr-5 text-[0.9375rem] sm:pr-16 sm:text-lg lg:text-xl 2xl:mb-9 3xl:text-2xl"
      >
        {{ page.lead }}
      </p>

      <NuxtLink
        v-if="page.hero_link"
        :to="page.hero_link"
        class="link button-primary after:!pr-4"
      >
        {{ page.hero_link_text }}</NuxtLink
      >

      <div v-else class="w-full pr-10">
        <button
          class="link button-primary overflow-visible text-left after:!pr-4"
          @click="$emit('scroll-to')"
        >
          {{ page.hero_link_text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
}
</script>
