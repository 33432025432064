import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["CustomerLogos","CustomerLogosAndTerms","Entry","EntryHero","Events","EventsAndTerms","Jobs","JobsAndTerms","NavigationAndGlobals","NewsArticles","NewsArticlesTeaser","OfferTerms","Offers","Terms","ContentElements","Document","Asset","BlueprintEvent","BlueprintJob","BlueprintNews","BlueprintPage"]}
export const GqlCustomerLogos = (...params) => useGql()('CustomerLogos', ...params)
export const GqlCustomerLogosAndTerms = (...params) => useGql()('CustomerLogosAndTerms', ...params)
export const GqlEntry = (...params) => useGql()('Entry', ...params)
export const GqlEntryHero = (...params) => useGql()('EntryHero', ...params)
export const GqlEvents = (...params) => useGql()('Events', ...params)
export const GqlEventsAndTerms = (...params) => useGql()('EventsAndTerms', ...params)
export const GqlJobs = (...params) => useGql()('Jobs', ...params)
export const GqlJobsAndTerms = (...params) => useGql()('JobsAndTerms', ...params)
export const GqlNavigationAndGlobals = (...params) => useGql()('NavigationAndGlobals', ...params)
export const GqlNewsArticles = (...params) => useGql()('NewsArticles', ...params)
export const GqlNewsArticlesTeaser = (...params) => useGql()('NewsArticlesTeaser', ...params)
export const GqlOfferTerms = (...params) => useGql()('OfferTerms', ...params)
export const GqlOffers = (...params) => useGql()('Offers', ...params)
export const GqlTerms = (...params) => useGql()('Terms', ...params)