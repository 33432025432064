<template>
  <div
    v-if="totalPages > 1"
    class="news-pagination mt-10 mb-28 flex w-full justify-center space-x-6 text-primary-blue sm:mt-24 sm:mb-52 sm:space-x-8"
  >
    <button
      v-if="currentPage !== 1"
      class="rotate-90"
      @click="$emit('previous-page')"
    >
      <ArrowSimpleIcon stroke-width="1.5" class="h-5 w-5 text-primary-blue" />
    </button>

    <ul class="flex items-baseline justify-center space-x-6 sm:space-x-8">
      <template v-for="index in totalPages" :key="index">
        <span
          v-if="index === currentPage - 2 && currentPage - 3 > 0"
          class="!ml-0 text-primary-blue"
          >...</span
        >

        <li
          v-show="!(index < currentPage - 2) && !(index > currentPage + 2)"
          class="flex flex-col items-center justify-center"
        >
          <button
            class="link text-xl font-medium"
            @click="$emit('go-to-page', index)"
          >
            {{ index }}
          </button>

          <div v-show="currentPage === index" class="active-element" />
        </li>

        <span v-if="index === currentPage + 2 && currentPage + 2 < totalPages"
          >...</span
        >
      </template>
    </ul>

    <button
      v-show="currentPage !== totalPages"
      class="-rotate-90"
      @click="$emit('next-page')"
    >
      <ArrowSimpleIcon class="h-5 w-5 text-primary-blue" stroke-width="1.5" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },

    totalPages: {
      type: Number,
      default: 1,
    },
  },
}
</script>
