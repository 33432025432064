import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordion, LazyButtonDefault, LazyComponentsLoader, LazyContainer, LazyCopyToClipboardPopup, LazyEventCard, LazyFilter, LazyHome, LazyInputField, LazyJobCard, LazyNewsArticlesTeaser, LazyNewsCard, LazyNewsDetail, LazyOfferSidebar, LazyPagination, LazySocialMediaShares, LazyBlueprintEvent, LazyBlueprintJobs, LazyBlueprintNews, LazyBlueprintPage, LazyContentParagraph, LazyCustomerLogos, LazyEventSetup, LazyEventsOverview, LazyJobSetup, LazyJobsOverview, LazyNewsArticles, LazyNewsletter, LazyOfferSelection, LazyHeroDefault, LazyHeroHome, LazyArrowRightIcon, LazyArrowSimpleIcon, LazyCalendarIcon, LazyCheckmarkIcon, LazyCopyIcon, LazyCrossIcon, LazyFacebookIcon, LazyFilterIcon, LazyLinkIcon, LazyLinkedinIcon, LazyLocationIcon, LazyPlayIcon, LazyPlusIcon, LazySpinIcon, LazyToCalendarIcon, LazyTogglerIcon, LazyTwitterIcon, LazyWatchIcon, LazyLogoIcon, LazyFooter, LazyLangPicker, LazyNavigation } from '#components'
const lazyGlobalComponents = [
  ["Accordion", LazyAccordion],
["ButtonDefault", LazyButtonDefault],
["ComponentsLoader", LazyComponentsLoader],
["Container", LazyContainer],
["CopyToClipboardPopup", LazyCopyToClipboardPopup],
["EventCard", LazyEventCard],
["Filter", LazyFilter],
["Home", LazyHome],
["InputField", LazyInputField],
["JobCard", LazyJobCard],
["NewsArticlesTeaser", LazyNewsArticlesTeaser],
["NewsCard", LazyNewsCard],
["NewsDetail", LazyNewsDetail],
["OfferSidebar", LazyOfferSidebar],
["Pagination", LazyPagination],
["SocialMediaShares", LazySocialMediaShares],
["BlueprintEvent", LazyBlueprintEvent],
["BlueprintJobs", LazyBlueprintJobs],
["BlueprintNews", LazyBlueprintNews],
["BlueprintPage", LazyBlueprintPage],
["ContentParagraph", LazyContentParagraph],
["CustomerLogos", LazyCustomerLogos],
["EventSetup", LazyEventSetup],
["EventsOverview", LazyEventsOverview],
["JobSetup", LazyJobSetup],
["JobsOverview", LazyJobsOverview],
["NewsArticles", LazyNewsArticles],
["Newsletter", LazyNewsletter],
["OfferSelection", LazyOfferSelection],
["HeroDefault", LazyHeroDefault],
["HeroHome", LazyHeroHome],
["ArrowRightIcon", LazyArrowRightIcon],
["ArrowSimpleIcon", LazyArrowSimpleIcon],
["CalendarIcon", LazyCalendarIcon],
["CheckmarkIcon", LazyCheckmarkIcon],
["CopyIcon", LazyCopyIcon],
["CrossIcon", LazyCrossIcon],
["FacebookIcon", LazyFacebookIcon],
["FilterIcon", LazyFilterIcon],
["LinkIcon", LazyLinkIcon],
["LinkedinIcon", LazyLinkedinIcon],
["LocationIcon", LazyLocationIcon],
["PlayIcon", LazyPlayIcon],
["PlusIcon", LazyPlusIcon],
["SpinIcon", LazySpinIcon],
["ToCalendarIcon", LazyToCalendarIcon],
["TogglerIcon", LazyTogglerIcon],
["TwitterIcon", LazyTwitterIcon],
["WatchIcon", LazyWatchIcon],
["LogoIcon", LazyLogoIcon],
["Footer", LazyFooter],
["LangPicker", LazyLangPicker],
["Navigation", LazyNavigation]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
