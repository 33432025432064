<template>
  <div class="news-card relative w-full max-w-xl">
    <NuxtLink
      :to="article.url"
      class="aspect-container relative w-full"
      :aria-label="`Link to page ${article.title}`"
    >
      <!-- IMAGE -->
      <img
        v-if="article.news_image"
        class="image h-full w-full object-cover"
        :src="article.news_image.permalink || article.news_image"
        :alt="article.news_image.image_alt || article.image_alt"
      />

      <!-- PLACEHOLDER -->
      <div v-else class="placeholder title absolute inset-0 flex items-end">
        <div class="px-8 py-16 text-white sm:p-16 sm:pr-20 sm:pb-28">
          {{ article.title }}
        </div>
      </div>
    </NuxtLink>

    <div class="py-8 px-3 pb-16 sm:px-6">
      <p class="date">{{ formatDate }}</p>
      <h3>
        <NuxtLink :to="article.url" class="title">{{ article.title }}</NuxtLink>
      </h3>
      <p class="text mb-7 text-base lg:text-xl">{{ article.summary }}</p>
    </div>
    <div class="link link-button absolute bottom-8 right-8">
      <NuxtLink class="button-primary" :to="article.url">{{
        translations.continue_reading
      }}</NuxtLink>
    </div>
  </div>
</template>

<script>
import { store } from '/store.js'

export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  computed: {
    translations() {
      return store.translations || {}
    },

    formatDate() {
      if (!this.article.published_at) return ''
      const locale = useI18n().locale.value
      return new Date(this.article.published_at).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })
    },
  },
}
</script>
