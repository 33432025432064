<template>
  <main class="flex min-h-screen">
    <NuxtPage />
  </main>
</template>

<script setup>
import { store } from '/store.js'
const { locale } = useI18n()

watch(locale, async () => {
  fetchGlobals()
})

const pageId = computed(() => store.pageId)

async function fetchGlobals() {
  const { data } = await useAsyncGql({
    operation: 'NavigationAndGlobals',
    variables: {
      site: locale,
    },
  })
  populateGlobals(data?.value)
}

function populateGlobals(globals) {
  // populate the store with the main nav for the breadcrumbs
  store.navigation = { main: globals.nav }

  if (!globals) return
  store.homeButtons = globals.home_buttons
  store.header = globals.header_content
  store.footer = globals.footer_content
  store.translations = globals.translations
  store.forms = globals.forms
  store.offerSelection = globals.offer_selection
}

// INITIAL
const { data } = await useAsyncGql({
  operation: 'NavigationAndGlobals',
  variables: {
    site: locale,
  },
})
populateGlobals(data?.value)
</script>
