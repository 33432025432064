<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 22 22"
  >
    <path
      fill="currentColor"
      fill-rule="nonzero"
      d="m18.232.232 3.536 3.536L14.535 11l7.233 7.232-3.536 3.536L11 14.535l-7.232 7.233-3.536-3.536L7.465 11 .232 3.768 3.768.232 11 7.465z"
    />
  </svg>
</template>
