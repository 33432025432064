<template>
  <div
    class="fixed right-0 top-0 z-20 h-screen w-full max-w-[100vw] overflow-y-auto bg-white px-10 py-12 transition-all sm:w-[80%] xl:w-[40%]"
  >
    <div class="card-overlay">
      <div class="flex w-full justify-end">
        <CrossIcon
          class="w-5 cursor-pointer text-primary-red"
          aria-label="Close"
          @click="$emit('close')"
        />
      </div>

      <div class="content">
        <h3 class="title">{{ data.title }}</h3>
        <div class="description">
          {{ data.description }}
        </div>

        <!-- KEY DATA -->
        <template v-if="hasKeyData">
          <h3 class="title info">
            {{ offerSelection.key_data }}
          </h3>
          <ul class="list">
            <template v-for="(item, index) in data.key_data" :key="index">
              <li v-if="item.text" class="item">
                <p>{{ item.text }}</p>
              </li>
            </template>
          </ul>
        </template>

        <div
          class="wrapper mt-12 flex flex-col gap-5 sm:mt-20 sm:flex-row-reverse sm:justify-between"
        >
          <!-- LOGO -->
          <div class="logo max-w-[40%] md:max-w-[35%]">
            <img
              v-if="data.partner?.logo || data.partner_logo"
              :src="
                data.partner?.logo.permalink || data.partner_logo?.permalink
              "
              :alt="
                data.partner?.logo.alt ||
                data.partner_logo?.alt ||
                'Logo des Partners'
              "
              class="image"
            />
          </div>

          <!-- CONTACT -->
          <div class="contact">
            <h3 v-if="hasContact" class="title !mb-3 !mt-8 sm:!mt-0">
              {{ offerSelection.contact_person }}
            </h3>
            <p
              v-if="data.contact_person"
              class="text person whitespace-pre-wrap"
              v-html="data.contact_person"
            />
            <a class="link email" :href="`mailto:${data.email}`">{{
              data.email
            }}</a>
            <a class="link tel" :href="`tel:${data.phone}`">{{ data.phone }}</a>
            <p class="text person2">{{ data.contact_person_2 }}</p>
            <a class="link email2" :href="`mailto:${data.email_2}`">{{
              data.email_2
            }}</a>
            <a class="link tel2" :href="`tel:${data.phone_2}`">{{
              data.phone_2
            }}</a>
          </div>
        </div>

        <!-- LINKS -->
        <div class="links flex flex-col">
          <template v-for="(link, index) in data.links" :key="index">
            <ButtonDefault class="w-fit" :to="link.link">
              {{ link.link_text }}</ButtonDefault
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '/store.js'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    offerSelection() {
      return store.offerSelection
    },

    hasContact() {
      return (
        !!this.data.contact_person ||
        !!this.data.contact_person_2 ||
        !!this.data.phone ||
        !!this.data.phone_2 ||
        !!this.data.email ||
        !!this.data.email_2
      )
    },

    hasKeyData() {
      if (!this.data.key_data) return false

      for (const value of Object.values(this.data.key_data)) {
        if (value.text) return true
      }

      return false
    },
  },

  mounted() {
    document.body.style.overflow = 'hidden'
  },

  beforeUnmount() {
    document.body.style.overflow = 'auto'
  },
}
</script>
