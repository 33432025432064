import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_jBToDIZpFa from "/vercel/path0/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import isExternalUrl_v00hVViRWA from "/vercel/path0/plugins/isExternalUrl.ts";
import livePreview_2CfOB765FN from "/vercel/path0/plugins/livePreview.js";
import removePTags_NNOtr2gdeF from "/vercel/path0/plugins/removePTags.ts";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import veeValidate_U1l0qzVbBC from "/vercel/path0/plugins/veeValidate.ts";
import vue_gtag_client_fbnXi0Aqdd from "/vercel/path0/plugins/vue-gtag.client.js";
import vue3_lottie_client_xXNcInN2H5 from "/vercel/path0/plugins/vue3-lottie.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_jBToDIZpFa,
  chunk_reload_client_UciE0i6zes,
  isExternalUrl_v00hVViRWA,
  livePreview_2CfOB765FN,
  removePTags_NNOtr2gdeF,
  sentry_client_GoGQuZo4Et,
  veeValidate_U1l0qzVbBC,
  vue_gtag_client_fbnXi0Aqdd,
  vue3_lottie_client_xXNcInN2H5
]