<template>
  <div class="job-card grid w-full grid-cols-1 gap-12 md:grid-cols-3 md:gap-0">
    <!-- IMAGE -->
    <div class="aspect-container relative col-span-1 w-full">
      <NuxtLink
        class="h-full w-full"
        target="_blank"
        :to="data.link"
        :aria-label="`Open event page ${data.title}`"
      >
        <img
          v-if="data.image"
          class="image h-full w-full object-cover"
          :src="data.image.permalink"
          :alt="data.image.alt"
        />

        <!-- PLACEHOLDER -->
        <div v-else class="placeholder absolute inset-0 flex items-center">
          <div class="title-white px-14">
            <div class="pt-16">{{ data.title }}</div>
          </div>
        </div>
      </NuxtLink>
    </div>
    <!-- TEXT -->
    <div class="md:col-span-2 md:pl-6 lg:pl-20">
      <h3>
        <NuxtLink target="_blank" :to="data.link" class="title"
          >{{ data.title }} {{ data.pensum ? data.pensum : '' }}</NuxtLink
        >
      </h3>

      <NuxtLink
        v-if="data.company_link"
        :to="data.company_link"
        target="_blank"
        class="subtitle mt-2"
      >
        {{ data.company_name }}
      </NuxtLink>

      <p v-else class="subtitle mt-2">{{ data.company_name }}</p>

      <p
        v-if="data.description"
        class="my-5 text-black md:my-6"
        v-html="data.description"
      />

      <!-- JOB META -->
      <div
        class="flex flex-wrap items-center justify-between space-y-1 text-black"
      >
        <div class="mr-4 flex items-center space-x-2">
          <LocationIcon class="mt-1 w-4 shrink-0 text-primary-blue" />
          <p class="mt-2">
            {{ data.location }}
            <span v-if="data.homeoffice">
              {{ translations.and_homeoffice }}
            </span>
          </p>
        </div>
        <div
          v-if="data.from_now || data.start"
          class="mr-4 flex items-center space-x-2"
        >
          <CalendarIcon class="w-5 shrink-0 text-primary-blue" />
          <p v-if="data.from_now">{{ translations.start_from_now }}</p>
          <p v-else>{{ data.start }}</p>
        </div>
        <div class="mr-4 flex items-center space-x-2">
          <WatchIcon class="w-5 shrink-0 text-primary-blue" />
          <p>
            {{ translations.published_at }}
            {{ data.in_join_created_at || data.created_at }}
          </p>
        </div>
      </div>

      <!-- LINKS -->
      <div
        class="mt-7 flex w-full flex-col flex-wrap justify-between gap-y-3 sm:flex-row md:mt-12"
      >
        <div class="link link-button">
          <ButtonDefault :to="data.link">
            {{ translations.to_job_advert }}
          </ButtonDefault>
        </div>

        <button
          v-if="isDetail"
          class="button-primary after:content-none"
          @click="copyToClipboard()"
        >
          <span
            class="copy-button mr-3 text-base font-medium tracking-widest"
            >{{ translations.link_to_share }}</span
          >
          <CopyIcon class="h-7 w-7" />
          <transition name="fade">
            <CopyToClipboardPopup v-show="showPopup">
              {{ translations.link_is_copied }}</CopyToClipboardPopup
            >
          </transition>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '/store.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    isDetail: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPopup: false,
    }
  },

  computed: {
    translations() {
      return store.translations
    },
  },

  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(
          this.$config.public.APP_URL + this.data.url
        )
        this.showPopup = true

        setTimeout(() => {
          this.showPopup = false
        }, 1000)
      } catch (err) {
        console.error('Failed to copy to clipboard: ', err)
      }
    },
  },
}
</script>
