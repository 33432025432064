<template>
  <section class="news-article module-default mb-32 pt-20 sm:mb-28">
    <div class="mx-auto max-w-[75rem]">
      <!-- TITLE & SUMMARY -->

      <div class="space-y-7 sm:w-3/4">
        <p class="date">{{ formatDate }}</p>
        <h1 class="title">{{ page.title }}</h1>
        <p class="summary">{{ page.summary }}</p>
      </div>

      <!-- IMAGE -->

      <div class="my-8 flex w-full sm:my-14 sm:justify-end">
        <figure v-if="page.news_image" class="my-8 sm:my-14 sm:w-3/4">
          <div class="image-container relative">
            <img
              class="relative"
              :src="page.news_image"
              :alt="page.image_alt"
            />
          </div>

          <figcaption v-if="page.image_alt" class="float-right mt-2 w-[40%]">
            <a
              target="_blank"
              class="float-right text-right text-xs"
              :href="page.news_image"
            >
              {{ page.image_alt }}
            </a>
          </figcaption>
        </figure>
      </div>

      <div
        v-if="page.content"
        ref="content"
        class="content sm:w-3/4"
        v-html="page.content"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formatDate() {
      if (!this.page.published_at) return ''
      const locale = useI18n().locale.value

      return new Date(this.page.published_at).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })
    },
  },

  mounted() {
    let aTags = this.$refs.content.getElementsByTagName('a')
    if (!aTags || aTags.length < 1) {
      return
    }

    aTags = [...aTags] // Convert to array from HTMLCollection
    aTags.forEach((aTag) => {
      aTag.setAttribute('target', '_blank')
    })
  },
}
</script>
