<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 37 20"
  >
    <path
      fill="currentColor"
      fill-rule="nonzero"
      d="m37 9-1.165 5.71L0 20l1.163-5.71L37 9zm0-9-.874 5.71L7 10l.872-5.71L37 0z"
    />
  </svg>
</template>
