<template>
  <div class="flex min-h-screen w-full items-center justify-center px-6">
    <div>
      <Navigation v-if="store.navigation" />
      <section>
        <div class="count-to-figure max-w-[55rem]">
          <h1 class="figure">
            <span class="number -large !leading-none">
              {{ error.statusCode }}
            </span>
            <span class="line my-8" />
            <span class="title">{{ errorText }}</span>
          </h1>
        </div>
      </section>
      <Footer class="absolute bottom-0 left-0" />
    </div>
  </div>
</template>

<script setup>
import { store } from '/store.js'

const props = defineProps({
  error: Object,
  required: true,
})

const { locale } = useI18n()

const error404 = ref({})
const error500 = ref({})
const error503 = ref({})

const errorText = computed(() => {
  switch (props.error.statusCode) {
    case '404':
      return error404.value?.text
    case '500':
      return error500.value?.text
    case '503':
      return error503.value?.text
    default:
      return 'Es ist ein Fehler aufgetreten.'
  }
})

const { data } = await useAsyncGql({
  operation: 'NavigationAndGlobals',
  variables: {
    site: locale,
  },
})
store.header = data?.value?.header_content
store.footer = data?.value?.footer_content
store.navigation = { main: data?.value?.nav }
error404.value = data?.value?.error_404
error500.value = data?.value?.error_500
error503.value = data?.value?.error_503
</script>
