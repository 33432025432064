<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 17"
    aria-hidden="true"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill-rule="nonzero" stroke="currentColor" stroke-width="1.5">
        <g>
          <g>
            <g>
              <path
                d="M29.491 7.513L.545 7.513M21.334 15l7.924-6.976c.147-.129.23-.315.23-.51s-.083-.381-.23-.51L21.334.027"
                transform="translate(-660 -484) translate(573 479) translate(87 5) translate(0 .667)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
