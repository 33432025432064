<template>
  <NuxtLink
    class="button-primary link"
    :class="{ '-is-external': $isExternalUrl(to) }"
    :target="$isExternalUrl(to) ? '_blank' : ''"
    :to="to"
  >
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },
}
</script>
