<template>
  <section>
    <div id="job-setup" class="job-setup relative">
      <div class="background-aslant module-default relative w-full px-6 pb-48">
        <div class="mx-auto max-w-[55rem]">
          <!-- TITLE -->
          <div class="mb-6 pt-24 sm:mb-8 sm:pt-40">
            <h1 class="title">{{ translations.job_form_title }}</h1>
          </div>

          <template v-if="state !== 'submitted'">
            <!-- TEXT -->
            <div class="mb-16 sm:mb-20">
              <p class="text">{{ translations.job_form_text }}</p>
            </div>
            <!-- SUB TITLE -->
            <div class="mb-6 mt-9 sm:mb-9 sm:mt-16">
              <h2 class="subtitle">{{ translations.job_form_subtitle }}</h2>
            </div>
          </template>

          <!-- FORM -->
          <transition name="fade" mode="out-in">
            <Form
              v-if="jobSetupForm && state !== 'submitted'"
              ref="form"
              v-slot="{ errors }"
              key="0"
              class="form"
              novalidate
              @submit="submit"
            >
              <div class="grid grid-cols-1 gap-5 sm:grid-cols-6 sm:gap-8">
                <template v-for="field in jobSetupForm.fields">
                  <!-- SECOND TITLE -->
                  <div
                    v-if="field.handle === 'contact_email'"
                    :key="field.handle"
                    class="col-span-1 mb-2 mt-9 sm:col-span-6 sm:mb-2 sm:mt-16"
                  >
                    <h2 class="subtitle">
                      {{ translations.job_form_contact_subtitle }}
                    </h2>
                  </div>

                  <!-- START, LOCATION, LINK AND COMPANY-LINK -->
                  <InputField
                    v-if="field.type === 'text'"
                    :id="field.handle"
                    :key="field.handle"
                    :value="form[field.handle]"
                    class="input relative"
                    :class="[
                      mapColSpan(field.width),
                      field.handle === 'start' && !!form.from_now
                        ? 'opacity-50'
                        : '',
                    ]"
                    :disabled="field.handle === 'start' && !!form.from_now"
                    :field="field"
                    :errors="errors"
                    :rules="
                      field.handle === 'start'
                        ? ['after:today', !form.from_now ? 'required' : '']
                        : jobSetupForm.rules[field.handle]
                    "
                    @field-input="form[field.handle] = $event"
                  />

                  <!-- TEXTAREA -->
                  <div
                    v-if="field.type === 'textarea'"
                    :key="field.handle"
                    class="input relative row-span-4"
                    :class="mapColSpan(field.width)"
                  >
                    <Field
                      v-model="form[field.handle]"
                      rules="max:300|required"
                      as="textarea"
                      :name="field.handle"
                      maxlength="300"
                      class="peer"
                      :class="{ '!border-primary-red': errors[field.handle] }"
                      :placeholder="translations.description_placeholder"
                    />
                    <label
                      class="peer-placeholder-shown:text-custom-light-blue-gray pointer-events-none absolute -top-3 left-3 mr-2 text-sm text-primary-blue-gray transition-all peer-placeholder-shown:top-[1.125rem] peer-placeholder-shown:!text-[1.1875rem] peer-focus:-top-3 peer-focus:!text-sm peer-focus:text-white"
                      :for="field.handle"
                      :class="{
                        '!text-primary-red peer-placeholder-shown:!text-custom-light-blue-gray':
                          errors[field.handle],
                      }"
                    >
                      {{ translations.description_placeholder }}
                    </label>

                    <p
                      v-if="errors[field.handle]"
                      class="error !mt-[-0.125rem] !text-sm"
                    >
                      {{ translations[field.handle] }}
                      {{ translations.is_required }}
                    </p>
                  </div>

                  <!-- CHECKBOX -->
                  <div
                    v-if="
                      field.type === 'toggle' && field.handle === 'from_now'
                    "
                    :key="field.handle"
                    class="custom-checkbox relative flex h-14 items-center"
                    :class="mapColSpan(field.width)"
                  >
                    <Field
                      :id="field.handle"
                      v-model="form[field.handle]"
                      data-set-opacity-on-input
                      class="h-6 w-6 cursor-pointer"
                      :name="field.handle"
                      value="true"
                      type="checkbox"
                    />

                    <label
                      :for="field.handle"
                      class="label cursor-pointer pl-5"
                    >
                      {{ translations[field.handle] }}
                    </label>

                    <p v-if="errors[field.handle]" class="error !text-sm">
                      {{ translations[field.handle] }}
                      {{ translations.is_required }}
                    </p>
                  </div>

                  <!-- TOGGLE -->
                  <div
                    v-if="field.type === 'toggle' && field.handle != 'from_now'"
                    :key="field.handle"
                    class="toggle-switch relative flex h-14 items-center justify-between"
                    :class="mapColSpan(field.width)"
                  >
                    <label class="label">{{
                      translations[field.handle]
                    }}</label>
                    <label class="switch">
                      <Field
                        v-model="form[field.handle]"
                        :name="field.handle"
                        type="checkbox"
                        value="true"
                      />
                      <span class="slider round" />
                    </label>
                    <p v-if="errors[field.handle]" class="error !text-sm">
                      {{ translations[field.handle] }}
                      {{ translations.is_required }}
                    </p>
                  </div>

                  <!-- DROPDOWN -->
                  <div
                    v-if="
                      field.type === 'select' &&
                      field.handle === 'professional_field'
                    "
                    :key="field.handle"
                    class="input custom-select relative"
                    :class="mapColSpan(field.width)"
                  >
                    <label
                      class="pointer-events-none absolute -top-3 left-3 mr-2 text-sm text-white"
                      :class="{
                        '!text-primary-red': errors[field.handle],
                      }"
                      :for="field.handle"
                    >
                      {{ translations.professional_fields }}
                    </label>
                    <Field
                      v-model="form[field.handle]"
                      as="select"
                      class="appearance-none !pr-12"
                      :name="field.handle"
                      rules="required"
                      :class="{
                        '!border-primary-red': errors[field.handle],
                      }"
                    >
                      <option
                        v-for="option in professionalFields"
                        :key="option.slug"
                        :value="option.slug"
                      >
                        {{ option.title }}
                      </option>
                    </Field>
                    <ArrowSimpleIcon
                      stroke-width="1.5"
                      class="pointer-events-none absolute right-7 top-6 w-4 text-white"
                    />

                    <p v-if="errors[field.handle]" class="error !text-sm">
                      {{ translations.professional_fields }}
                      {{ translations.is_required }}
                    </p>
                  </div>
                </template>

                <input name="honigchuebel" type="hidden" />
              </div>
              <button
                class="button-secondary button mt-9 whitespace-nowrap !tracking-widest sm:mt-20"
                type="submit"
              >
                {{ translations.job_form_button_text }}
              </button>
            </Form>

            <!-- SUCCESS -->
            <div
              v-else-if="state === 'submitted'"
              ref="success"
              key="1"
              class="flex items-start space-x-4 text-white"
            >
              <CheckmarkIcon class="mt-2 h-4 w-4 shrink-0" />
              <p class="success">
                {{ translations.job_success_message }}
              </p>
            </div>

            <!-- ERROR -->
            <div
              v-else-if="state === 'error'"
              ref="error"
              key="2"
              class="my-10 flex items-start text-primary-red"
            >
              <p>
                {{ translations.submit_error }}
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { store } from '@/store'
import { Form, Field } from 'vee-validate'

export default {
  components: {
    Form,
    Field,
  },

  async setup() {
    const { data } = await useAsyncGql({
      operation: 'Terms',
      variables: {
        taxonomy: 'professional_fields',
        filter: {
          site: useI18n().locale,
        },
      },
    })

    const professionalFields = ref(data.value?.terms.data || {})

    return {
      professionalFields,
    }
  },

  data() {
    return {
      form: {},
      state: 'default',
    }
  },

  computed: {
    translations() {
      return store.translations
    },

    jobSetupForm() {
      return store.forms?.job_setup || {}
    },
  },

  methods: {
    mapColSpan(width = 100) {
      switch (width) {
        case 33:
          return 'sm:col-span-2 col-span-1'
        case 50:
          return 'sm:col-span-3 col-span-1'
        case 66:
          return 'sm:col-span-4 col-span-1'
        default:
          return 'sm:col-span-6 col-span-1'
      }
    },

    scrollTo(element) {
      if (!element) return

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    },

    async submit() {
      this.state = 'loading'

      try {
        await $fetch(`${this.$config.public.API_URL}/!/forms/job_setup`, {
          method: 'POST',
          body: this.form,
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        })

        this.state = 'submitted'
        await this.$nextTick()
        this.scrollTo(this.$refs.success)
      } catch (e) {
        this.state = 'error'

        console.error(e)

        await this.$nextTick()
        this.scrollTo(this.$refs.error)
      }
    },
  },
}
</script>
