<template>
  <section>
    <div id="event-setup" class="job-setup relative">
      <div class="background-aslant module-default relative w-full px-6 pb-48">
        <div class="mx-auto max-w-[55rem]">
          <!-- TITLE -->
          <div class="mb-6 pt-24 sm:mb-8 sm:pt-40">
            <h1 class="title">{{ translations.event_form_title }}</h1>
          </div>

          <template v-if="state !== 'submitted'">
            <!-- TEXT -->
            <div class="mb-16 sm:mb-20">
              <p class="text">{{ translations.event_form_text }}</p>
            </div>
            <!-- SUB TITLE -->
            <div class="mb-6 mt-9 sm:mb-9 sm:mt-16">
              <h2 class="subtitle">{{ translations.event_form_subtitle }}</h2>
            </div>
          </template>

          <!-- FORM -->

          <Form
            v-if="eventSetupForm"
            key="0"
            ref="form"
            v-slot="{ errors }"
            class="form"
            novalidate
            @submit="submit"
          >
            <div class="grid grid-cols-2 gap-5 sm:grid-cols-12 sm:gap-8">
              <template v-for="field in eventSetupForm.fields">
                <!-- SECOND TITLE -->
                <div
                  v-if="field.handle === 'event_contact_email'"
                  :key="field.handle"
                  class="col-span-1 mb-2 mt-9 sm:col-span-6 sm:mb-2 sm:mt-16"
                >
                  <h2 class="subtitle">
                    {{ translations.event_form_contact_subtitle }}
                  </h2>
                </div>

                <InputField
                  v-if="field.type === 'text' || field.type === 'email'"
                  :id="field.handle"
                  :key="field.handle"
                  :value="form[field.handle]"
                  class="input relative"
                  :class="[mapColSpan(field.width)]"
                  :field="field"
                  :errors="errors"
                  :rules="
                    field.handle === 'event_location'
                      ? [!form.event_online ? 'required' : '']
                      : field.handle === 'event_time_until'
                      ? [`after_time:${form['event_time']}`, 'required']
                      : eventSetupForm.rules[field.handle]
                  "
                  @field-input="form[field.handle] = $event"
                />

                <!-- TEXTAREA -->
                <div
                  v-if="field.type === 'textarea'"
                  :key="field.handle"
                  class="input relative row-span-4"
                  :class="mapColSpan(field.width)"
                >
                  <Field
                    v-model="form[field.handle]"
                    rules="max:250|required"
                    as="textarea"
                    :name="field.handle"
                    maxlength="250"
                    class="peer"
                    :class="{ '!border-primary-red': errors[field.handle] }"
                  />
                  <label
                    class="peer-placeholder-shown:text-custom-light-blue-gray pointer-events-none absolute -top-3 left-3 mr-2 text-sm text-white transition-all peer-placeholder-shown:top-[1.125rem] peer-placeholder-shown:!text-[1.1875rem] peer-focus:-top-3 peer-focus:!text-sm peer-focus:text-white"
                    :for="field.handle"
                    :class="{
                      '!text-primary-red peer-placeholder-shown:!text-custom-light-blue-gray':
                        errors[field.handle],
                    }"
                  >
                    {{ translations[field.handle] }}
                  </label>

                  <p
                    v-if="errors[field.handle]"
                    class="error !mt-[-0.125rem] !text-sm"
                  >
                    {{ translations[field.handle] }}
                    {{ translations.is_required }}
                  </p>
                </div>

                <!-- CHECKBOX -->
                <div
                  v-if="field.type === 'toggle' && field.handle === 'from_now'"
                  :key="field.handle"
                  class="custom-checkbox relative flex h-14 items-center"
                  :class="mapColSpan(field.width)"
                >
                  <Field
                    :id="field.handle"
                    v-model="form[field.handle]"
                    class="h-6 w-6 cursor-pointer"
                    :name="field.handle"
                    value="true"
                    type="checkbox"
                  />

                  <label :for="field.handle" class="label cursor-pointer pl-5">
                    {{ translations[field.handle] }}
                  </label>

                  <p v-if="errors[field.handle]" class="error !text-sm">
                    {{ translations[field.handle] }}
                    {{ translations.is_required }}
                  </p>
                </div>

                <!-- TOGGLE -->
                <div
                  v-if="field.type === 'toggle' && field.handle != 'from_now'"
                  :key="field.handle"
                  class="toggle-switch relative flex h-14 items-center justify-between"
                  :class="mapColSpan(field.width)"
                >
                  <label class="label">{{ translations[field.handle] }}</label>
                  <label class="switch">
                    <Field
                      v-model="form[field.handle]"
                      :name="field.handle"
                      type="checkbox"
                      value="true"
                    />
                    <span class="slider round" />
                  </label>
                  <p v-if="errors[field.handle]" class="error !text-sm">
                    {{ translations[field.handle] }}
                    {{ translations.is_required }}
                  </p>
                </div>

                <!-- DROPDOWN -->
                <div
                  v-if="
                    field.type === 'select' && field.handle === 'event_type'
                  "
                  :key="field.handle"
                  class="input custom-select relative"
                  :class="mapColSpan(field.width)"
                >
                  <label
                    class="pointer-events-none absolute -top-3 left-3 mr-2 text-sm text-white"
                    :class="{
                      '!text-primary-red': errors[field.handle],
                    }"
                    :for="field.handle"
                  >
                    {{ translations.event_type }}
                  </label>
                  <Field
                    v-model="form[field.handle]"
                    as="select"
                    class="appearance-none !pr-12"
                    :name="field.handle"
                    rules="required"
                    :class="{
                      '!border-primary-red': errors[field.handle],
                    }"
                  >
                    <option
                      v-for="option in eventTypes"
                      :key="option.slug"
                      :value="option.slug"
                    >
                      {{ option.title }}
                    </option>
                  </Field>
                  <ArrowSimpleIcon
                    stroke-width="1.5"
                    class="pointer-events-none absolute right-7 top-6 w-4 text-white"
                  />

                  <p v-if="errors[field.handle]" class="error !text-sm">
                    {{ translations.professional_fields }}
                    {{ translations.is_required }}
                  </p>
                </div>

                <!-- FILE UPLOAD -->
                <div
                  v-if="field.type === 'assets'"
                  :key="field.handle"
                  class="input relative row-span-4 h-14 w-full rounded-[0.25rem] border-2 border-white font-[1.1875rem] text-white outline-none"
                  :class="[
                    mapColSpan(field.width),
                    { '!border-primary-red': errors[field.handle] },
                  ]"
                >
                  <Field
                    v-model="form[field.handle]"
                    :name="field.handle"
                    class="peer cursor-pointer opacity-0"
                    type="file"
                    accept="image/*"
                    rules="image"
                  />
                  <label
                    class="pointer-events-none absolute -top-3 left-3 text-sm text-primary-blue-gray transition-all line-clamp-1 peer-focus:-top-3 peer-focus:!text-sm peer-focus:text-white sm:line-clamp-none"
                    :for="field.handle"
                    :class="[
                      {
                        '!text-primary-red peer-placeholder-shown:!text-custom-light-blue-gray':
                          errors[field.handle],
                      },
                      form[field.handle]
                        ? ''
                        : 'top-[1.125rem] !text-[1.1875rem] text-custom-light-blue-gray',
                    ]"
                  >
                    {{ translations[field.handle] }}
                  </label>

                  <!-- TEXT & BUTTON OVERLAY -->
                  <div
                    class="pointer-events-none absolute inset-0 flex items-center justify-between"
                  >
                    <p
                      class="w-auto max-w-full overflow-hidden text-ellipsis px-4"
                    >
                      {{ form[field.handle]?.name }}
                    </p>
                    <div
                      class="flex h-full items-center bg-white px-4 text-base font-medium uppercase text-primary-blue"
                    >
                      {{ translations.event_upload_image_button }}
                    </div>
                  </div>

                  <p
                    v-if="errors[field.handle]"
                    class="error !mt-[-0.125rem] !text-sm"
                  >
                    {{ translations[field.handle] }}
                    {{ translations.is_required }}
                  </p>
                </div>
              </template>

              <input name="honigchuebel" type="hidden" />
            </div>
            <button
              class="button-secondary button mt-9 whitespace-nowrap !tracking-widest sm:mt-20"
              type="submit"
            >
              {{ translations.job_form_button_text }}
            </button>
          </Form>

          <!-- SUCCESS -->
          <transition name="fade">
            <div
              v-if="state === 'submitted'"
              ref="success"
              key="1"
              class="my-10 flex items-start space-x-4 text-white"
            >
              <CheckmarkIcon class="mt-2 h-4 w-4 shrink-0" />
              <p class="success">
                {{ translations.event_success_message }}
              </p>
            </div>
          </transition>

          <!-- ERROR -->
          <transition name="fade">
            <div
              v-if="state === 'error'"
              key="2"
              ref="error"
              class="my-10 flex items-start text-primary-red"
            >
              <p>
                {{ translations.submit_error }}
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { store } from '@/store'
import { Form, Field } from 'vee-validate'

export default {
  components: {
    Form,
    Field,
  },

  async setup() {
    const { data } = await useAsyncGql({
      operation: 'Terms',
      variables: {
        taxonomy: 'event_types',
        filter: {
          site: useI18n().locale,
        },
      },
    })

    const eventTypes = ref(data.value?.terms.data || {})

    return {
      eventTypes,
    }
  },

  data() {
    return {
      form: {},
      state: 'default',
    }
  },

  computed: {
    translations() {
      return store.translations
    },

    eventSetupForm() {
      return store.forms?.event_setup || {}
    },
  },

  methods: {
    mapColSpan(width = 100) {
      switch (width) {
        case 25:
          return 'sm:col-span-3 col-span-1'
        case 33:
          return 'sm:col-span-4 col-span-2'
        case 50:
          return 'sm:col-span-6 col-span-2'
        case 66:
          return 'sm:col-span-8 col-span-2'
        default:
          return 'sm:col-span-12 col-span-2'
      }
    },

    scrollTo(element) {
      if (!element) return

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    },

    async submit(values, { resetForm }) {
      this.state = 'loading'

      if (!this.form.event_image) {
        // statamic throws an error if the field exists an is undefined
        delete this.form.event_image
      }

      // convert this.form to a form data instance
      const formData = new FormData()
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key])
      })

      try {
        await $fetch(`${this.$config.public.API_URL}/!/forms/event_setup`, {
          method: 'POST',
          body: formData,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })

        // Wait until the models are updated in the UI
        await this.$nextTick()
        this.form = {}

        this.state = 'submitted'

        this.scrollTo(this.$refs.success)

        resetForm()
      } catch (e) {
        this.state = 'error'

        console.error(e)

        await this.$nextTick()
        this.scrollTo(this.$refs.error)
      }
    },
  },
}
</script>
